/**
 * Imports
 */
import * as React from 'react';
import { css } from 'styled-components';

import { styled } from 'theme';

/**
 * Styling
 */
const SNACKBAR_TYPES: SnackbarLookup = {
  warning: css`
    background: ${(props) => props.theme.colors.Feedback.Warning};
    color: ${(props) => props.theme.colors.Text.Warning};
  `,
  error: css`
    background: ${(props) => props.theme.colors.Feedback.Danger};
    color: ${(props) => props.theme.colors.Text.Danger};
  `,
  info: css`
    background: ${(props) => props.theme.colors.Feedback.Info};
    color: ${(props) => props.theme.colors.Text.Info};
  `,
  success: css`
    background: ${(props) => props.theme.colors.Feedback.Success};
    color: ${(props) => props.theme.colors.Text.Success};
  `,
};
const Root = styled.div<{ type: SnackbarTypes }>`
  ${(props) => props.type && SNACKBAR_TYPES[props.type]}
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 160%; /* or 27px */
  padding: 16px 32px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

/**
 * Types
 */
export type SnackbarLookup = {
  [k in SnackbarTypes]: any;
};
type SnackbarTypes = 'error' | 'warning' | 'info' | 'success';
export interface SnackbarProps {
  text: React.ReactNode;
  type: SnackbarTypes;
}

/**
 * Snackbar component
 */
const Snackbar: React.FC<SnackbarProps> = ({ text, type }) => {
  return <Root type={type}>{text}</Root>;
};

/**
 * Exports
 */
export { Snackbar };
